<template>
  <v-fade-transition leave-absolute>
    <v-overlay :value="updating" v-if="updating" absolute>
      <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
    </v-overlay>
    <div v-else class="touchpoints-management">
      <div
        class="touchpoints-management__content"
        :class="{ 'touchpoints-management__content--expanded': drawerFilters }"
      >
        <UiBanner>
          <template v-slot:content>
            <div class="tw-flex tw-items-center">
              <div>
                <UiTitle :title="`${totalTouchpoints} Touchpoints`" large />
              </div>
              <v-spacer />
              <v-badge
                class="tw-ml-4"
                overlap
                color="primary"
                :content="currentFilters.length"
                :value="currentFilters.length"
                ><v-btn
                  outlined
                  color="primary"
                  rounded
                  :icon="$vuetify.breakpoint.xsOnly"
                  @click="toggleFilters(true)"
                >
                  <v-icon :left="$vuetify.breakpoint.smAndUp">{{ icons.mdiFilterOutline }}</v-icon>
                  <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('button.filter') }}</span>
                </v-btn></v-badge
              >
              <v-btn
                class="tw-ml-4"
                :fab="$vuetify.breakpoint.xsOnly"
                :small="$vuetify.breakpoint.xsOnly"
                elevation="2"
                color="primary"
                rounded
                @click="goToCreate()"
              >
                <v-icon :left="$vuetify.breakpoint.smAndUp">{{ icons.mdiPlusThick }}</v-icon>
                <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('button.create') }}</span>
              </v-btn>
              <!-- MENU DOWNLOAD -->
              <v-menu offset-y left :close-on-content-click="false" transition="slide-y-reverse-transition">
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-btn icon v-bind="attrs" v-on="{ ...menu }" class="tw-ml-4 tw---mr-1">
                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item link @click="downloadCSV">
                    <div class="tw-mr-4">
                      <v-progress-circular size="24" width="3" v-if="csvWorking" indeterminate></v-progress-circular>
                      <v-icon v-else>{{ icons.mdiMicrosoftExcel }}</v-icon>
                    </div>

                    <v-list-item-content>
                      <v-list-item-title class="tw-flex tw-flex-row tw-items-center"
                        ><span>{{ $t('touchpoints.management.actions.csv') }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip class="tw-ml-2" small color="primary">{{ computedSelectedNumber }}</v-chip>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item link @click="downloadZip">
                    <div class="tw-mr-4">
                      <v-progress-circular size="24" width="3" v-if="zipWorking" indeterminate></v-progress-circular>
                      <v-icon v-else>{{ icons.mdiFolderZipOutline }}</v-icon>
                    </div>

                    <v-list-item-content>
                      <v-list-item-title class="tw-flex">
                        <span>{{ $t('touchpoints.management.actions.qrCodes') }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip class="tw-ml-2" small color="primary">{{ computedSelectedNumber }} </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item :disabled="computedSelectedProductNumber === 0" link @click="refreshLanding">
                    <div class="tw-mr-4">
                      <v-progress-circular size="24" width="3" v-if="refreshingLP" indeterminate></v-progress-circular>
                      <v-icon v-else>{{ icons.mdiWebRefresh }}</v-icon>
                    </div>

                    <v-list-item-content>
                      <v-list-item-title class="tw-flex">
                        <span v-if="computedSelectedProductNumber === null">{{
                          $t('touchpoints.management.actions.refreshLp.labelAll')
                        }}</span>
                        <span v-else>{{ $t('touchpoints.management.actions.refreshLp.label') }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip v-if="computedSelectedProductNumber !== null" class="tw-ml-2" small color="primary"
                        >{{ computedSelectedProductNumber }}
                      </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
        </UiBanner>
        <div class="touchpoints-management__content__main">
          <UiContainer no-padding>
            <div class="touchpoints-management__content__main__toolbar" :class="{ 'theme--dark': $vuetify.theme.dark }">
              <v-text-field
                :value="search"
                solo
                flat
                outlined
                placeholder="Search"
                dense
                :prepend-inner-icon="icons.mdiMagnify"
                hide-details
                @input="searchTouchpoints"
              />
            </div>
            <div
              class="touchpoints-management__content__main__table-container touchpoints-management__content__main__table-container--search"
            >
              <v-data-table
                v-model="selected"
                :headers="computedHeaders"
                :items="touchpoints"
                fixed-header
                :single-select="false"
                item-key="_id"
                show-select
                height="100%"
                :loading="refreshing"
                class="tw-rounded-none tw-h-full tw-flex tw-flex-col touchpoints-management__content__main__table-container__table"
                :options.sync="options"
                :multi-sort="false"
                must-sort
                :server-items-length="touchpointsPagination.total"
                :footer-props="{
                  itemsPerPageOptions: [25, 50, 100],
                }"
                @toggle-select-all="selectAllTouchpoints"
                @click:row="editTouchpoint"
              >
                <template
                  v-slot:[`body.prepend`]="{ headers }"
                  v-if="allSelected && selected.length === touchpointsPagination.itemsPerPage"
                >
                  <tr>
                    <td :colspan="headers.length">
                      <div>
                        {{
                          $t('touchpoints.management.table.allSelected.label', {
                            limitPage: selected.length,
                          })
                        }}
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-slot:[`item.createdAt`]="{ item }"> {{ timeFromNow(item.createdAt, 'L LT') }} </template>
                <template v-slot:[`item.name`]="{ item }">
                  <div class="tw-flex tw-items-center" v-if="item.type === 'custom'">
                    <v-icon class="tw-mr-2" small>{{ icons.mdiPinwheelOutline }}</v-icon>
                    <div class="tw-flex tw-flex-col">
                      <span v-dompurify-html="highlightText(item.description, search)" />
                      <a
                        v-dompurify-html="highlightText(item.url.source, search)"
                        :href="item.url.source"
                        class="tw-text-xs"
                        target="_blank"
                        @click.stop
                      />
                    </div>
                  </div>
                  <div class="tw-flex tw-items-center" v-else-if="item.type === 'product'">
                    <v-icon class="tw-mr-2" small>{{ icons.mdiShoppingOutline }}</v-icon>
                    <div class="tw-flex tw-flex-col">
                      <div class="tw-flex tw-flex-row tw-items-center">
                        <v-chip
                          v-dompurify-html="highlightText(item.product.sku, search)"
                          class="tw-mr-2 tw-h-4 tw-font-mono"
                          small
                          dark
                        />
                        <span v-dompurify-html="` ${highlightText(item.product.name, search)}`" />
                      </div>
                      <a
                        v-dompurify-html="highlightText(item.url.source, search)"
                        :href="item.url.source"
                        class="tw-text-xs"
                        target="_blank"
                        @click.stop
                      />
                    </div>
                  </div>
                </template>
                <template v-slot:[`item.lastLpUpdate`]="{ item }">
                  {{
                    item.lastLpUpdate
                      ? `${$t('touchpoints.management.table.landingpage')} ${timeFromNow(item.lastLpUpdate, 'L LT')}`
                      : '-'
                  }}
                </template>
                <template v-slot:[`item.quantity`]="{ item }">
                  <div v-if="item.quantity === null">-</div>
                  <div v-else>{{ item.quantity }}</div>
                </template>
                <template v-slot:[`item.qrCodeUrl`]="{ item }">
                  <span class="tw-mr-2"><a target="_blank" :href="item.qrCodeUrl.pngUrl" @click.stop>PNG</a></span>
                  <span><a target="_blank" :href="item.qrCodeUrl.svgUrl" @click.stop>SVG</a></span>
                </template>
                <template v-slot:no-data>
                  <div class="tw-p-6 tw-mt-6 tw-border-dashed tw-border tw-rounded-sm">
                    <div v-if="search === ''">
                      <div>{{ $t('touchpoints.management.table.nodata.label') }}</div>
                      <v-btn class="tw-mt-4" elevation="2" color="primary" rounded @click="goToCreate()">
                        <v-icon :left="$vuetify.breakpoint.smAndUp">{{ icons.mdiPlusThick }}</v-icon>
                        <span v-if="$vuetify.breakpoint.smAndUp">{{
                          $t('touchpoints.management.table.nodata.button')
                        }}</span>
                      </v-btn>
                    </div>
                    <div v-else>{{ $t('touchpoints.management.table.noresults') }}</div>
                  </div>
                </template>
              </v-data-table>
            </div>
          </UiContainer>
        </div>
      </div>
      <TouchpointsListFilters
        ref="filters"
        :criteria="filters"
        :drawer="drawerFilters"
        :currentFilters="currentFilters"
        @toggle="toggleFilters"
        @filter="filterView"
      />
    </div>
  </v-fade-transition>
</template>

<script>
import { timeFromNow } from '@/utils/date.util'
import {
  mdiPlusThick,
  mdiMagnify,
  mdiFilterOutline,
  mdiFolderZipOutline,
  mdiPinwheelOutline,
  mdiShoppingOutline,
  mdiDotsVertical,
  mdiMicrosoftExcel,
  mdiWebRefresh,
} from '@mdi/js'
import { downloadFromUrl } from '@/utils/zip.util'
import { debounce } from '@/utils/utilities.util'
import { highlightText } from '@/utils/formatter.util'
import { mapActions, mapState, mapGetters } from 'vuex'
import UiTitle from '@/components/UI/Title.vue'
import UiBanner from '@/components/UI/Banner.vue'
import UiContainer from '@/components/UI/Container.vue'
import TouchpointsListFilters from '@/components/Touchpoints/List/Filters.vue'

export default {
  name: 'TouchpointsList',
  components: {
    UiBanner,
    UiTitle,
    UiContainer,
    TouchpointsListFilters,
  },
  data: () => ({
    highlightText,
    options: {
      sortDesc: [true],
      sortBy: ['createdAt'],
    },
    selected: [],
    refreshing: false,
    drawerFilters: false,
    allSelected: false,
    csvWorking: false,
    zipWorking: false,
    refreshingLP: false,
    timeFromNow,
    icons: {
      mdiFolderZipOutline,
      mdiPlusThick,
      mdiMagnify,
      mdiFilterOutline,
      mdiPinwheelOutline,
      mdiShoppingOutline,
      mdiDotsVertical,
      mdiMicrosoftExcel,
      mdiWebRefresh,
    },
  }),
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (newOptions !== oldOptions) {
          this.loadTouchpoints()
          if (this.allSelected) {
            this.allSelected = false
            this.selected = []
          }
        }
      },
      deep: true,
    },
  },
  async created() {
    this.setUpdating(true)
    await this.getFilters()
    this.setUpdating(false)
  },
  computed: {
    ...mapState({
      updating: state => state.backoffice.updating,
      currentBrand: state => state.backoffice.currentClient?.brandId,
      products: state => state.touchpoints.products,
      touchpoints: state => state.touchpoints.touchpoints,
      touchpointsPagination: state => state.touchpoints.touchpointsPagination,
      filters: state => state.touchpoints.filters,
      currentFilters: state => state.touchpoints.currentFilters,
      search: state => state.touchpoints.search,
      searchRules: state => state.touchpoints.searchRules,
      downloadUrl: state => state.touchpoints.downloadUrl,
      working: state => state.backoffice.working,
    }),
    ...mapGetters({
      totalTouchpoints: 'touchpoints/totalTouchpoints',
    }),
    computedHeaders() {
      return [
        { text: this.$t('touchpoints.management.table.headers.creation'), value: 'createdAt' },
        { text: this.$t('touchpoints.management.table.headers.destination'), value: 'name', sortable: false },
        { text: this.$t('touchpoints.management.table.headers.lastupdate'), value: 'lastLpUpdate' },
        { text: this.$t('touchpoints.management.table.headers.quantity'), value: 'quantity' },
        { text: this.$t('touchpoints.management.table.headers.scans'), value: 'stats.hits' },
        { text: this.$t('touchpoints.management.table.headers.started'), value: 'stats.gameStarted' },
        { text: this.$t('touchpoints.management.table.headers.ended'), value: 'stats.gameEnded' },
        { text: this.$t('touchpoints.management.table.headers.qrCodes'), value: 'qrCodeUrl', sortable: false },
      ]
    },
    computedSelectedNumber() {
      return this.selected.length === 0 ? this.totalTouchpoints : this.selected.length
    },

    computedSelectedProductNumber() {
      const selectedProducts = this.selected.filter(select => select.type === 'product')
      return this.selected.length === 0 ? null : selectedProducts.length
    },
  },
  methods: {
    ...mapActions({
      setUpdating: 'backoffice/setUpdating',
      resetState: 'touchpoints/resetState',
      searchAllTouchpoints: 'touchpoints/searchAllTouchpoints',
      getFilters: 'touchpoints/getFilters',
      setFiltersRules: 'touchpoints/setFiltersRules',
      setSearchRules: 'touchpoints/setSearchRules',
      setSearch: 'touchpoints/setSearch',
      setCurrentFilters: 'touchpoints/setCurrentFilters',
      getDownloadUrl: 'touchpoints/getDownloadUrl',
      getCSVDownloadUrl: 'touchpoints/getCSVDownloadUrl',
      refreshLP: 'touchpoints/refreshLP',
      setAlert: 'backoffice/setAlert',
    }),

    async loadTouchpoints() {
      this.refreshing = true
      try {
        await this.searchAllTouchpoints({
          tableParams: this.options,
        })
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('error.notification.default'),
        })
      }
      this.refreshing = false
    },

    searchTouchpoints: debounce(async function (value) {
      const filters = [
        {
          field: 'url.source',
          values: [value],
          operator: 'contains',
        },
        {
          field: 'product.productBrand',
          values: [value],
          operator: 'contains',
        },
        {
          field: 'product.sku',
          values: [value],
          operator: 'contains',
        },
        {
          field: 'product.name',
          values: [value],
          operator: 'contains',
        },
        {
          field: 'description',
          values: [value],
          operator: 'contains',
        },
      ]
      this.setSearch(value)
      this.setSearchRules(filters)
      this.resetOptions()
    }, 300),

    filterView(filters) {
      this.setCurrentFilters(filters)
      const clearedFilters = this.filteredSearch(filters.filter(filter => filter.values.length))
      this.setFiltersRules(clearedFilters)

      this.toggleFilters(false)
      this.resetOptions()
    },

    filteredSearch(filters) {
      const clearedFilters = filters.map(filter => {
        return {
          field: filter.slug,
          values: filter.values,
          operator: 'in',
        }
      })
      return clearedFilters
    },

    toggleFilters(bool) {
      this.drawerFilters = bool
    },

    selectAllTouchpoints(options) {
      if (options.value) {
        this.allSelected = true
      } else {
        this.allSelected = false
      }
    },
    goToCreate() {
      this.$router.push({ name: 'TouchpointsCreation' })
    },
    async downloadZip() {
      this.zipWorking = true
      const idsTable = this.selected.map(touchpoint => touchpoint._id)
      const downloadUrl = await this.getDownloadUrl({
        _ids: idsTable,
        search: this.searchRules,
        filters: this.filteredSearch(this.currentFilters),
      })
      downloadFromUrl(downloadUrl)
      this.zipWorking = false
    },
    async downloadCSV() {
      this.csvWorking = true
      const idsTable = this.selected.map(touchpoint => touchpoint._id)
      await this.getCSVDownloadUrl({
        _ids: idsTable,
        search: this.searchRules,
        filters: this.filteredSearch(this.currentFilters),
      })
      this.csvWorking = false
    },
    async refreshLanding() {
      this.refreshingLP = true
      const idsTable = this.selected.map(touchpoint => touchpoint._id)
      await this.refreshLP({
        _ids: idsTable,
        search: this.searchRules,
        filters: this.filteredSearch(this.currentFilters),
      })
      this.resetOptions()
      this.refreshingLP = false
    },
    editTouchpoint(item) {
      this.$router.push({ name: 'TouchpointsEdition', params: { id: item._id } })
    },
    resetOptions() {
      this.options = {
        ...this.options,
        page: 1,
      }
      this.selected = []
      this.allSelected = false
    },
  },
}
</script>

<style lang="scss">
.touchpoints-management {
  &__content {
    @apply tw-w-full tw-h-full tw-flex tw-flex-col;

    transition: padding-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);

    &__main {
      @apply tw-w-full tw-h-full tw-flex tw-flex-col;
      &--expanded {
        @media (min-width: map-get($grid-breakpoints, 'md')) {
          padding-left: 256px;
        }
      }
      &__toolbar {
        @apply tw-flex tw-items-center tw-gap-4 tw-p-4 sm:tw-px-6;

        border-bottom: 1px solid map-deep-get($material-light, 'banner', 'border');
        height: $toolbar-height;

        &.theme--dark {
          border-bottom: 1px solid map-deep-get($material-dark, 'banner', 'border');
        }
      }

      &__table-container {
        @apply tw-absolute tw-right-0 tw-bottom-0 tw-left-0;

        top: 0;

        & mark {
          font-weight: 600;
          background-color: #fdde53;
          padding: 0 1px;
        }

        &--search {
          top: $toolbar-height;
        }

        &__table tbody tr:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
